export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~5],
		"/admin": [~6,[2]],
		"/admin/artikler-tiny": [~8,[2]],
		"/admin/artikler": [~7,[2]],
		"/admin/db-funktioner": [9,[2]],
		"/admin/edit-user/[id]": [~10,[2]],
		"/admin/hashtester": [11,[2]],
		"/admin/ingredienser": [~12,[2]],
		"/admin/kategorier": [~13,[2]],
		"/admin/kontaktformularen": [~14,[2]],
		"/admin/opskrifter": [15,[2]],
		"/admin/recipeimages": [~16,[2]],
		"/admin/settings": [~17,[2]],
		"/admin/temaer": [~18,[2]],
		"/admin/vaegttyper": [~19,[2]],
		"/artikler/[rubrik]": [~20],
		"/bruger-ingredienser": [~21],
		"/bruger-opskrifter": [~22],
		"/favoritter": [~23],
		"/follow": [~24],
		"/ingredienser/[kategori]/[[slug]]": [~27],
		"/ingrediensredigering": [~28],
		"/ingrediens/[id]/[slug]": [~26],
		"/ingr/[kategori]/[[slug]]": [~25],
		"/kategorier/[slug]/[[subkat]]": [~29],
		"/kontakter": [~31],
		"/kontakt": [30],
		"/madfaka-gl": [~32],
		"/madfakta": [~33],
		"/mine-opskrifter": [~34],
		"/ny-opskrift/[[id]]": [~35],
		"/opskrifter/[slug]": [~37,[],[4]],
		"/opskrifter/[memberid]/[niceurl]": [~36,[],[3]],
		"/password-reset": [~38],
		"/plus-bruger": [39],
		"/private-kategorier": [~40],
		"/privatlivsindstillinger": [41],
		"/profil": [~42],
		"/search": [~43],
		"/signup": [~44],
		"/tema/[temanavn]": [~45]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';